import "@rainbow-me/rainbowkit/styles.css";

import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./screens/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { WalletConfig } from "./hooks/WalletConfig";

import "./i18n";
import Profile from "./screens/Profile";
import React from "react";
import Shop from "./screens/Shop";
import Airdrop from "./screens/Airdrop";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/airdrop",
    element: <Airdrop />,
  },
]);

const queryClient = new QueryClient();

const App = () => {
  return (
    <WagmiProvider config={WalletConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider locale="en-US">
          <Router>
            <Navbar />
            <Routes>
              <Route element={<Home />} path="/" />
              <Route element={<Profile />} path="/profile" />
              <Route element={<Shop />} path="/shop" />
              <Route element={<Airdrop />} path="/airdrop" />
            </Routes>
            <Footer />
          </Router>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
