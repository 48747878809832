import React, { useCallback, useEffect, useState, useRef } from "react";
import { INVITER_ADDR, SELL_MANAGER, USDT_TOKEN } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import { getArticleList, getNftList } from "../../requests";

import "./home.less";

import homeTopBg from "@/assets/img/home/home_top_bg.png";
import homeTopBgPc from "@/assets/img/home/home_top_bg_pc.png";
import homeLogo from "@/assets/img/home/home_logo.png";
import homeLogoCn from "@/assets/img/cnimg/homeLogoCn.png";
import jinren from "@/assets/img/home/jinren.png";
import martLogo from "@/assets/img/home/mart_logo.png";
import martTitle from "@/assets/img/home/mart_title.png";
import homeContent from "@/assets/img/home/home_content.png";
import adoptLogo from "@/assets/img/home/adopt_logo.png";
import slogan_robot from "@/assets/img/home/slogan_logo_robot.png";
import slogan_text from "@/assets/img/home/slogan_logo_slogan.png";
import adoptListTitle from "@/assets/img/home/adopt_list_title.png";
import adoptListItemLogo from "@/assets/img/home/adopt_list_item_logo.png";
import currBnb from "@/assets/img/home/curr_bnb.png";
import nftTitle from "@/assets/img/home/nft_title.png";
import colorBar from "@/assets/img/home/color_bar.png";
import whatItMira from "@/assets/img/home/What_it_MiRA.png";
import whatItMiraCn from "@/assets/img/cnimg/whatItMiraCn.png";
import missionVision from "@/assets/img/home/Mission_Vision.png";
import missionVisionCn from "@/assets/img/cnimg/missionVisionCn.png";
import More from "@/assets/img/home/More.png";
import AmbassadorProgram from "@/assets/img/home/Ambassador_Program.png";
import JointheMovement from "@/assets/img/home/Join_the_Movement.png";
import logotwo from "@/assets/img/header/logotwo.png";
import rankone from "@/assets/img/home/rankone.png";
import ranktwo from "@/assets/img/home/ranktwo.png";
import ranktherr from "@/assets/img/home/ranktherr.png";
import miraNews from "@/assets/img/home/mira_news.png";
import miraNewsCn from "@/assets/img/cnimg/miraNewsCn.png";
import clickToView from "@/assets/img/home/click_to_view.png";
import clickToViewCn from "@/assets/img/cnimg/clickToViewCn.png";
import close from "@/assets/img/home/close.png";
import binance from "@/assets/img/home/partncrs/binance.png";
import bscscan from "@/assets/img/home/partncrs/bscscan.png";
import chainbow from "@/assets/img/home/partncrs/chainbow.png";
import coingecko from "@/assets/img/home/partncrs/coingecko.png";
import coinmarketcap from "@/assets/img/home/partncrs/coinmarketcap.png";
import daliu from "@/assets/img/home/partncrs/daliu.png";
import dimensional from "@/assets/img/home/partncrs/dimensional.png";
import linghe from "@/assets/img/home/partncrs/linghe.png";
import pancakeswap from "@/assets/img/home/partncrs/pancakeswap.png";
import wallet3 from "@/assets/img/home/partncrs/wallet3.png";
import tianji from "@/assets/img/home/partncrs/tianji.png";
import betab from "@/assets/img/home/partncrs/betab.png";
import jwt from "@/assets/img/home/partncrs/jwt.png";
import q91 from "@/assets/img/home/partncrs/q91.png";
import gongde from "@/assets/img/home/partncrs/gongde.png";
import partners from "@/assets/img/home/partners.png";
import partnersCn from "@/assets/img/home/partnersCn.png";

import useInterval from "../../hooks/useInterval";
import { getBalanceNumber, toBigString } from "../../utils/formatBalance";
import BigNumber from "bignumber.js";
import { usdtTokenContract } from "../../hooks/usdtTokenContract";
import { createPublicClient, http } from "viem";
import { arbitrum, bsc } from "wagmi/chains";
import { useAccount } from "wagmi";
import useWithdrawToken from "../../hooks/useWithdrawToken";
import useTokenApprove from "../../hooks/useTokenApprove";
import NftList from "../../Components/NftList";
import useIsMobile from "../../hooks/useIsMobile";
import Footer from "../../Components/Footer";
import { message, Tooltip } from "antd";
import { formatAddress } from "../../utils/utils";
import { getLang } from "../../utils/getLang";

const Home = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;

  const isMobile = useIsMobile();
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();

  const [iosUrl, setIosUrl] = useState("https://i.diawi.com/MRr2vq")
  // Mart币种列表
  const [adoptList, setAdoptList] = useState([
    {
      id: 0,
      logo: adoptListItemLogo, // logo
      name: "0x1234..3321", // 名字
      currLogo: currBnb, // 代币logo
      currPrice: 3, // 代币价格
      currSymbol: "TMIR", // 代币符号
      order: 1,
      image: "",
    },
  ]);

  useEffect(() => {
    const channelFetch = async () => {
      if (channel != null) {
        try {
          console.log(channel);
          const list = await fetch(
            API_SERVER + "/common/visit?channel=" + channel
          );
          const resList = await list.json();
          console.log(resList);
        } catch (e) {}
      }
    };

    const fetchData = async () => {
      try {
        const response = await fetch(API_SERVER + "/common/getTopUserList");
        const result = await response.json();
        console.log(result);
        if (result.data) {
          let arry = [];
          for (let i = 0; i < result.data.length; i++) {
            let obj = {
              id: i,
              logo: adoptListItemLogo,
              name: formatAddress(result.data[i].address),
              currLogo: currBnb,
              currPrice: result.data[i].tmir,
              currSymbol: "TMIR",
              order: result.data[i].order,
              image: result.data[i].image,
            };
            arry.push(obj);
          }
          setAdoptList(arry);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    channelFetch();
    fetchData();
  }, [setAdoptList, setIosUrl]);

  // NFT列表
  const [nftList, setNftList] = useState([]);

  const { address } = useAccount();
  const publicClient = createPublicClient({ chain: bsc, transport: http() });
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const onChange = (page: any) => {
    setCurrent(page);
    getNftListData(nftType, page);
  };

  const [usdtAllowance, setUsdtAllowance] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  // NFt列表分类名FO:佛牌，MH：盲盒，空为所有
  const [nftType, setNftType] = useState("");
  const getNftListData = async (
    type: string = nftType,
    pageNum: number = current
  ) => {
    const obj = {
      pageSize: 6,
      pageNum,
      lang: lang === "cn" ? "zh" : "en",
      type,
    };
    const resList = await getNftList(obj);
    if (resList["code"] === 200) {
      const listRow = resList["data"];
      setNftList(listRow);
      setTotal(resList["total"]);
    }
  };
  const fetchSellNftList = useCallback(async () => {
    try {
      const baseAllowance = await publicClient.readContract({
        ...usdtTokenContract,
        address: USDT_TOKEN,
        functionName: "allowance",
        args: [address as `0x${string}`, SELL_MANAGER as `0x${string}`],
      });
      const baseAllowanceValue = getBalanceNumber(
        new BigNumber(baseAllowance.toString())
      );
      console.info("baseAllowance=>" + baseAllowance);
      setUsdtAllowance(baseAllowanceValue);

      const usdtBalance = await publicClient.readContract({
        ...usdtTokenContract,
        address: USDT_TOKEN,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      });
      const usdtBalanceValue = getBalanceNumber(
        new BigNumber(usdtBalance.toString())
      );
      console.info("usdtBalanceValue=>" + usdtBalanceValue);
      setUsdtBalance(usdtBalanceValue);
    } catch (e) {
      console.error(e);
    }
  }, [
    setNftList,
    setTotal,
    setUsdtAllowance,
    setUsdtBalance,
    current,
    address,
    SELL_MANAGER,
    USDT_TOKEN,
  ]);
  useInterval(fetchSellNftList);

  const { doApprove } = useTokenApprove(bsc);
  const { doWithdraw } = useWithdrawToken(bsc);
  // NFT购买
  const Buy = async (buyNftItem: any) => {
    console.log("Buy-NFT", buyNftItem);
    if (!address) {
      return message.error({
        icon: (
          <img
            src={close}
            alt=""
            style={{ width: "20px", marginRight: "10px" }}
          />
        ),
        content: t("error_linkwallet"),
      });
    }
    if (usdtBalance < parseFloat(buyNftItem.price.toString())) {
      return message.error({
        icon: (
          <img
            src={close}
            alt=""
            style={{ width: "20px", marginRight: "10px" }}
          />
        ),
        content: t("error_usdt"),
      });
    }
    if (usdtAllowance < parseFloat(buyNftItem.price.toString())) {
      message.error({
        icon: (
          <img
            src={close}
            alt=""
            style={{ width: "20px", marginRight: "10px" }}
          />
        ),
        content: t("error_approve"),
      });
      await doApprove(USDT_TOKEN, SELL_MANAGER);
      return;
    }

    await doWithdraw(buyNftItem.id, buyNftItem.price.toString(), 1);
  };

  const noAppDownload = (e: any) => {
    e.preventDefault();
    message.error({
      icon: (
        <img
          src={close}
          alt=""
          style={{ width: "20px", marginRight: "10px" }}
        />
      ),
      content: t("coming soon"),
    });
  };

  // 文章列表
  const [articleList, setArticleList] = useState([]);

  const fetchArticleList = async () => {
    const downSet = await fetch(
        API_SERVER + "/common/getConfig?key=IOS_DOWN_URL"
    );
    const downSetRs = await downSet.json();
    console.log("URL==>"+downSetRs.data);
    // @ts-ignore
    setIosUrl(downSetRs.data)
    const res = await getArticleList();
    console.log("文章列表", res);
    if (res.data) {
      setArticleList(res.data);
    }
  };
  // NFt列表分类
  const onTabMallChange = async (type: string) => {
    setCurrent(1);
    setNftType(type);
    getNftListData(type, 1);
  };

  // 合作伙伴列表
  const partnersList = [
    {
      logo: coingecko,
      link: "https://www.coingecko.com/",
    },
    {
      logo: coinmarketcap,
      link: "https://coinmarketcap.com/",
    },
    {
      logo: pancakeswap,
      link: "https://pancakeswap.finance/",
    },
    {
      logo: binance,
      link: "https://www.binance.com/en",
    },
    {
      logo: daliu,
      link: "https://www.daliuren.com",
    },
    {
      logo: tianji,
      link: "https://www.tianjizone.com/",
    },
    {
      logo: bscscan,
      link: "https://bscscan.com",
    },
    {
      logo: linghe,
      link: "",
    },
    {
      logo: dimensional,
      link: "https://www.binance.com/",
    },
    {
      logo: chainbow,
      link: "https://chainbow.io/",
    },
    {
      logo: wallet3,
      link: "https://wallet3.io",
    },
    {
      logo: betab,
      link: "https://www.betabpr.com",
    },
    // {
    //   logo: q91,
    //   link: "https://www.betabpr.com/blank",
    // },
    {
      logo: gongde,
      link: "",
    },
    {
      logo: jwt,
      link: "",
    },
  ];

  useEffect(() => {
    fetchArticleList();
    getNftListData();
  }, [setArticleList, setIosUrl]);

  return (
    <div className="home">
      <div className="home_bg">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <img src={homeTopBg} alt="" className="home_top_bg" />
        <img src={homeTopBgPc} alt="" className="home_top_bg_pc" />
      </div>
      <div className="home_content">
        <div className="home_logo">
          <img src={logotwo} alt="" className="header_logotwo" />
          {lang === "en" ? (
            <img src={homeLogo} alt="" />
          ) : (
            <img src={homeLogoCn} alt="" />
          )}
        </div>
        <div className="adopts_btn">
          {/*<Link className="adopts" to="/shop">
            {t("NFTMart adopts")}
          </Link>*/}
          <a href={iosUrl} className="adopts">
            {t("IOS Download")}
          </a>
          <a href="https://www.miraweb3.com/upImages/app-release.apk" className="adopts">
            {t("Android Download")}
          </a>
        </div>
        <div className="home_jinren">
          <img src={jinren} alt=""/>
        </div>
        <div className="mart_content">
          <img src={martLogo} alt="" className="mart_logo" />
          {lang === "en" ? (
            <img src={whatItMira} alt="" className="mart_title" />
          ) : (
            <img src={whatItMiraCn} alt="" className="mart_title" />
          )}
          <p>{t("martTitle")}</p>
        </div>
      </div>
      <div className="home_content_pc">
        <div className="home_content_pc_top">
          <div className="home_content_pc_left">
            <div className="home_logo">
              {lang === "en" ? (
                <img src={homeLogo} alt="" />
              ) : (
                <img src={homeLogoCn} alt="" className="home_logo_cn" />
              )}
            </div>
            <div className="home_content_pc_btn">
              {/*<Link className="adopts" to="/shop">
                {t("NFTMart adopts")}
              </Link>*/}
              <a href={iosUrl} className="adopts">
                {t("IOS Download")}
              </a>
              <a href="https://www.miraweb3.com/upImages/app-release.apk" className="adopts ">
                {t("Android Download")}
              </a>
            </div>
          </div>
          <div className="home_content_pc_right">
            <img src={jinren} alt="" />
          </div>
        </div>
        <div className="home_content_pc_bom">
          <img src={martLogo} alt="" className="mart_logo" />
          {lang === "en" ? (
            <img src={whatItMira} alt="" className="mart_title" />
          ) : (
            <img src={whatItMiraCn} alt="" className="mart_title" />
          )}
          <p>{t("martTitle")}</p>
        </div>
      </div>
      <div className="home_con">
        <div className="home_con_bg">
          <img src={homeContent} alt="" className="home_content" />
        </div>
        <img src={colorBar} alt="" className="color_bar" />
        <div id="nft_mall" className="home_con_content">
          <div className="home_con_content_main">
            {/*<NftList
              nftList={nftList}
              doBuy={Buy}
              onChange={onChange}
              current={current}
              total={total}
              isProfile={false}
              onTabMallChange={onTabMallChange}
            />

            <div className="adopt_list">
              <div className="adopt_list_title">
                <div className="adopt_list_title_left">
                  <img
                    src={adoptLogo}
                    alt=""
                    className="adopt_list_title_logo"
                  />
                  <div className="adopt_list_title_t">
                    {t("NFT Ownership Ranking")}
                  </div>
                </div>
              </div>
              <div className="adopt_list_con">
                {adoptList.map((item, i) => (
                  <div key={i} className="adopt_list_item">
                    {item.order === 1 && (
                      <img
                        src={rankone}
                        alt=""
                        className="adopt_list_item_rank"
                      />
                    )}
                    {item.order === 2 && (
                      <img
                        src={ranktwo}
                        alt=""
                        className="adopt_list_item_rank"
                      />
                    )}
                    {item.order === 3 && (
                      <img
                        src={ranktherr}
                        alt=""
                        className="adopt_list_item_rank"
                      />
                    )}
                    {item.order > 3 && (
                      <div className="adopt_list_item_rank_num_con">
                        <div className="adopt_list_item_rank_num">
                          {item.order}
                        </div>
                      </div>
                    )}

                    <img
                      src={item.image}
                      alt=""
                      className="adopt_list_item_logo"
                    />
                    <div className="adopt_list_item_con">
                      <div className="name">{item.name}</div>
                      <div className="curr_info">
                        <div className="curr_info_left">
                          <div className="curr_box">
                            <img
                              src={item.currLogo}
                              alt=""
                              className="curr_logo"
                            />
                          </div>
                          <span className="curr_price">{item.currPrice}</span>
                          <span className="curr_symbol">{item.currSymbol}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>*/}

            <div className="adopt_box">
              <div className="adopt_box_con">
                <div className="adopt_title">
                  {lang === "en" ? (
                    <img src={missionVision} alt="" />
                  ) : (
                    <img src={missionVisionCn} alt="" />
                  )}
                </div>
                <div className="adopt_desc">
                  <div className="adopt_desc_con">
                    <div className="adopt_desc_box">
                      <h3>{t("desc one")}</h3>
                      <p>{t("adopt desc one")}</p>
                    </div>
                    <div className="adopt_desc_box">
                      <h3>{t("desc two")}</h3>
                      <p>{t("adopt desc two")}</p>
                    </div>

                    <div className="adopt_desc_box">
                      <h3>{t("desc three")}</h3>
                      <p>{t("adopt desc three")}</p>
                    </div>
                    <div className="adopt_desc_box">
                      <h3>{t("desc four")}</h3>
                      <p>{t("adopt desc four")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nft_logo">
              <img src={adoptLogo} alt="" className="nft_logo_icon" />
              {lang === "en" ? (
                <img src={miraNews} alt="" className="nft_logo_title" />
              ) : (
                <img src={miraNewsCn} alt="" className="nft_logo_title" />
              )}
            </div>

            <div className="mart_article">
              <div className="mart_article_title">
                {lang === "en" ? (
                  <img src={clickToView} alt="" />
                ) : (
                  <img src={clickToViewCn} alt="" />
                )}
              </div>
               <div className="mart_article_box">
                <h3>{t("mart article one")}</h3>
                <p>{t("mart article two")}</p>
              </div>
              {articleList.map((item: any, i) => (
                <a
                  href={item.url}
                  className="mart_article_box"
                  key={i}
                  target="_blank"
                >
                  <div className="mart_article_box_title">
                    <Tooltip
                      title={
                        lang === "en" ? item.noticeTitle : item.noticeTitleZh
                      }
                      trigger={["hover", "click"]}
                    >
                      <h3 className="mart_article_box_title_t_m">
                        {lang === "en" ? item.noticeTitle : item.noticeTitleZh}
                      </h3>
                    </Tooltip>
                    <h3 className="mart_article_box_title_t_p">
                      {lang === "en" ? item.noticeTitle : item.noticeTitleZh}
                    </h3>
                    <div className="more_btn">
                      {lang === "en" ? (
                        <img src={More} alt="" />
                      ) : (
                        <div>{t("More")}</div>
                      )}
                    </div>
                  </div>
                  <p>
                    {lang === "en" ? item.noticeContent : item.noticeContentZh}
                  </p>
                </a>
              ))}
            </div>
          </div>

          <div style={{ marginTop: 10 }}></div>

          <div className="slogan_logo">
             <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScGAo0wWVjLqT8q1WkiHqYnqbXKR-Noc1V8MLD60FKJ5nWKHA/viewform?usp=pp_url"
              className="apply_btn"
              target="_blank"
            >
              {t("Apply")}
            </a>
            {lang === "en" ? (
              <img src={slogan_text} style={{ width: 180 }} />
            ) : (
              <img src={homeLogoCn} alt="" style={{ width: 180 }} />
            )}

            <div className="slogan_logo_jiqi">
              <img src={slogan_robot} />
            </div>
          </div>

           {/*{!isMobile ? <Footer /> : <div />}*/}
        </div>


        <img src={colorBar} alt="" className="color_bar" />
        <div className="home_partners">
          <div className="home_partners_title">
            {lang === "en" ? (
              <img src={partners} alt="" />
            ) : (
              <img src={partnersCn} alt="" />
            )}
          </div>
          <div className="home_partners_list">
            {partnersList.map((item, i) =>
              item.link ? (
                <a
                  href={item.link}
                  key={i}
                  target="_blank"
                  className="home_partner_item"
                >
                  <img src={item.logo} alt="" />
                </a>
              ) : (
                <span key={i} className="home_partner_item">
                  <img src={item.logo} alt="" />
                </span>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
