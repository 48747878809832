import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {useAccount, useSignMessage, useWalletClient} from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useTranslation } from "react-i18next";
import { message, Modal } from "antd";
import enUS from "antd/locale/en_US";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import "./navbar.less";
import {getSignInInfo, getSignIn, getUserInfo} from "../requests/index.js";
import { getLang } from "../utils/getLang";

import moreIcon from "@/assets/img/header/more.png";
import headerBon from "@/assets/img/header/header_bon.png";
import gou from "@/assets/img/header/gou.png";
import home from "@/assets/img/header/home.png";
import whitepaper from "@/assets/img/header/whitepaper.png";
import shoppingmall from "@/assets/img/header/shoppingmall.png";
import linkwallet from "@/assets/img/header/linkwallet.png";
import login from "@/assets/img/header/login.png";
import download from "@/assets/img/header/download.png";
import line from "@/assets/img/header/line.png";
import logoImg from "@/assets/img/header/logo.png";
import langImg from "@/assets/img/header/lang.png";
import CIS from "@/assets/img/header/Checked_in_Successfully.png";
import CISCN from "@/assets/img/cnimg/Checked_in_SuccessfullyCn.png";
import juanone from "@/assets/img/header/juanone.png";
import juantwo from "@/assets/img/header/juantwo.png";
import my from "@/assets/img/header/my.png";
import langicon from "@/assets/img/header/langicon.png";
import logoone from "@/assets/img/header/logoone.png";
import close from "@/assets/img/home/close.png";

dayjs.locale("en");

const PLANT_TYPE = process.env.REACT_APP_PLANT_TYPE;
const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const { address, isConnected } = useAccount();
  const lang = getLang();

  const [locale, setLocal] = useState(enUS);
  const changeLocale = (e: any) => {
    const localeValue = e.target.value;
    setLocal(localeValue);
    if (!localeValue) {
      dayjs.locale("en");
    } else {
      dayjs.locale("zh-cn");
    }
  };
  const onWaitAlert = () => {
    message.error({
      icon: (
        <img
          src={close}
          alt=""
          style={{ width: "20px", marginRight: "10px" }}
        />
      ),
      content: "Coming soon",
    });
  };

  const langList = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "cn",
      label: "中文",
    },
  ];

  const [acLang, setAcLang] = useState(localStorage.getItem("lang") || "en");
  const [isShowLang, setIsShowLang] = useState(false);

  const [isShowMenu, setIsShowMenu] = useState(false);

  const switchLang = (val: string) => {
    i18n.changeLanguage(val);
    setAcLang(val);
    setIsShowLang(false);
    localStorage.setItem("lang", val);
    // window.location.reload();
  };

  // 菜单点击
  const menuHandler = () => {
    setIsShowMenu(false);
  };
  const noAppDownload = () => {
    message.error({
      icon: (
        <img
          src={close}
          alt=""
          style={{ width: "20px", marginRight: "10px" }}
        />
      ),
      content: t("coming soon"),
    });
  };

  const { signMessage } = useSignMessage();
  const { data: walletClient } = useWalletClient()
  const [isShowCheckIn, setIsShowCheckIn] = useState(false);
  const [checkInNum, setCheckInNum] = useState(0);
  const checkIn = async () => {
    if (isCheckIn) {
      return message.error({
        icon: (
          <img
            src={close}
            alt=""
            style={{ width: "20px", marginRight: "10px" }}
          />
        ),
        content: t("I have already signed in today"),
      });
    }
    if (!address)
      return message.error({
        icon: (
          <img
            src={close}
            alt=""
            style={{ width: "20px", marginRight: "10px" }}
          />
        ),
        content: t("Please connect the wallet first"),
      });

    const objUser = {
      address: address.toLowerCase(),
    };
    const userInfo = await getSignInInfo(objUser);
    const signMessage = userInfo['data']['signMessage'];
    const params = ("address="+ address!.toLowerCase() + "\nmessage=Hello" + "\ntime=" + signMessage);
    // @ts-ignore
    const signature = await walletClient.signMessage({message: params})
    console.info("message=  " + params);
    console.info("signature=  " + signature);

    if(!signature)return ;
    const obj = {
      address: address?.toLowerCase(),
      message: params,
      signature: signature,
    };
    const res = await getSignIn(obj);
    console.log("sign result", res);
    if (res.data) {
      setCheckInNum(res.data.amount);
      document.body.classList.add("modal_open");
      setIsShowCheckIn(true);
      setIsShowMenu(false);
      setIsCheckIn(true);
    }
  };

  const checkInCancel = () => {
    setIsShowCheckIn(false);
  };
  const afterOpenChange = (open: boolean) => {
    if (!open) {
      document.body.classList.remove("modal_open");
    }
  };
  // 签到确定按钮
  const checkInOk = () => {
    setIsShowCheckIn(false);

    // window.location.reload();
  };
  // 是否签到
  const [isCheckIn, setIsCheckIn] = useState(false);
  // 签到
  const signIn = async () => {
    // const address = "0x5a4d4b03ae40cf74bbda36f402509d65037fe222";
    if (!address) return;
    const obj = {
      address: address?.toLowerCase(),
    };
    const res = await getSignInInfo(obj);
    if (res.data) {
      setIsCheckIn(res.data.sign);
    }
    // console.log("是否签到", res);
  };
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // 锚点
  const anchorLink = (e: any) => {
    e.preventDefault();
    const ele = document.getElementById("nft_mall");
    if (pathname === "/") {
      ele && ele.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/shop");
      setSelectPage("/shop");
    }
  };
  // 回到页面顶部
  const homeTop = () => {
    if (pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  };

  // 选中的页面 / 首页 /shop 商城页面
  const [selectPage, setSelectPage] = useState("/");

  useEffect(() => {
    if (!address) return;
    signIn();
  }, [address]);

  useEffect(() => {
    setSelectPage(pathname);
  }, [pathname]);

  // 滚动事件
  const handleScroll = () => {
    setIsShowMenu(false);
  };
  useEffect(() => {
    if (isShowMenu) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isShowMenu]);

  return (
    <div className="header">
      <div className="header_top">
        <Link to="/" className="logo" onClick={homeTop}>
          <img src={logoImg} alt="" className="logo_pc" />
          <img src={logoone} alt="" className="logo_mobile" />
        </Link>
        <div className="header_left">
          <div className="header_link">
            <Link
              to="/"
              replace={false}
              onClick={() => setSelectPage("/")}
              className={selectPage === "/" ? "active_link" : ""}
            >
              <div className="name">{t("Home")}</div>
            </Link>
            {/*<a
              href="#nft_mall"
              onClick={anchorLink}
              className={selectPage === "/shop" ? "active_link" : ""}
            >
              <div className="name">{t("Shopping Mall")}</div>
            </a>*/}
          </div>
          <a className="check_in check_in_pc" href="https://bud-api.miraweb3.xyz">
            {isCheckIn ? t("AIRDROP") : t("AIRDROP")}
            {!isCheckIn && <div className="check_in_dian"></div>}
          </a>
          <ConnectButton label={t("Connect Wallet")} />
          <Link to="/profile" className="ma_account">
            <img src={my} alt="" />
          </Link>
          <div
            className="lang_box_pc"
            onMouseEnter={() => setIsShowLang(true)}
            onMouseLeave={() => setIsShowLang(false)}
          >
            <div className="lang_box_item">
              <div className="lang" onClick={() => setIsShowLang(true)}>
                <img src={langicon} alt="" />
              </div>
            </div>

            <ul
              className={
                isShowLang
                  ? "lang_list lang_list_show"
                  : "lang_list lang_list_hide"
              }
            >
              {langList.map((item) => (
                <li
                  key={item.value}
                  className="lang_list_item"
                  onClick={() => switchLang(item.value)}
                >
                  {item.label}
                  <img
                    src={gou}
                    alt=""
                    className={
                      acLang === item.value
                        ? "lang_list_item_img_active"
                        : "lang_list_item_img"
                    }
                  />
                </li>
              ))}
            </ul>
          </div>
          {/*  className = checked_in */}
          <a className="check_in check_in_mobile" href="https://bud-api.miraweb3.xyz">
            {isCheckIn ? t("AIRDROP") : t("AIRDROP")}
            {!isCheckIn && <div className="check_in_dian"></div>}
          </a>
          <div
            className="more"
            onMouseEnter={() => setIsShowMenu(true)}
            onMouseLeave={() => setIsShowMenu(false)}
          >
            <img src={moreIcon} alt="" onClick={() => setIsShowMenu(true)} />
            <ul
              className={
                isShowMenu
                  ? "menu_list menu_list_show"
                  : "menu_list menu_list_hide"
              }
            >
              {/* 此次如果要跳转外部链接，使用a标签 */}
              <li onClick={menuHandler}>
                <Link to="/" replace={false}>
                  <img src={home} alt="" />
                  <div className="name">{t("Home")}</div>
                </Link>
              </li>
              {/* <img src={line} alt="" className="line" />
              <li onClick={menuHandler}>
                <a href="/WhitePaper.pdf" target="_blank">
                  <img src={whitepaper} alt="" />
                  <div className="name">{t("White paper")}</div>
                </a>
              </li> */}
              <img src={line} alt="" className="line" />
              {/*<li onClick={menuHandler}>
                <a href="#nft_mall" onClick={anchorLink}>
                  <img src={shoppingmall} alt="" />
                  <div className="name">{t("Shopping Mall")}</div>
                </a>
              </li>
              <img src={line} alt="" className="line" />*/}
              <li
                className="lang_box"
                onMouseEnter={() => setIsShowLang(true)}
                onMouseLeave={() => setIsShowLang(false)}
              >
                <div className="lang_box_item">
                  <img src={langImg} alt="" />
                  <div className="lang" onClick={() => setIsShowLang(true)}>
                    <span>{t("Switch language")}</span>
                  </div>
                </div>

                <ul
                  className={
                    isShowLang
                      ? "lang_list lang_list_show"
                      : "lang_list lang_list_hide"
                  }
                >
                  {langList.map((item) => (
                    <li
                      key={item.value}
                      className="lang_list_item"
                      onClick={() => switchLang(item.value)}
                    >
                      {item.label}
                      <img
                        src={gou}
                        alt=""
                        className={
                          acLang === item.value
                            ? "lang_list_item_img_active"
                            : "lang_list_item_img"
                        }
                      />
                    </li>
                  ))}
                </ul>
              </li>
              {/*<img src={line} alt="" className="line" />
              <li onClick={menuHandler}>
                <img src={linkwallet} alt="" />
                <div className="name">
                  {t("Link Wallet")}
                </div>
              </li>*/}
              <img src={line} alt="" className="line" />
              <li onClick={menuHandler}>
                <Link to="/profile">
                  <img src={login} alt="" />
                  <div className="name">{t("Login")}</div>
                </Link>
              </li>
              <img src={line} alt="" className="line" />

              <li onClick={noAppDownload} className="no_app_download">
                <Link to="/">
                  <img src={download} alt="" />
                  <div className="name">{t("APP Download")}</div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <img src={headerBon} className="header_bottom_img" />

      <Modal
        open={isShowCheckIn}
        onCancel={checkInCancel}
        afterOpenChange={afterOpenChange}
        centered
        footer={null}
        closable={false}
        className="check_in_modal"
      >
        <div className="check_in_modal_tit">{t("Congratulations")}</div>
        <div className="check_in_modal_tit_bg"></div>
        {lang === "en" ? (
          <img src={CIS} alt="" className="check_in_CIS" />
        ) : (
          <img src={CISCN} alt="" className="check_in_CIS" />
        )}
        <div className="check_in_modal_get">
          <span className="check_in_modal_get_text">{t("Get")}</span>
          <span className="check_in_modal_get_num">{checkInNum} TMIR</span>
        </div>
        <div className="check_in_modal_bom">
          <div className="check_in_modal_crown_one"></div>
          <div className="check_in_modal_crown_two"></div>
          <div className="check_in_modal_crown_three"></div>
          <div className="check_in_modal_btn" onClick={checkInOk}>
            {t("Determine")}
          </div>
          <Link
            to="https://discord.com/invite/miraweb3"
            className="check_in_modal_link"
            target="_blank"
            onClick={checkInCancel}
          >
            {t("You can view it in 'My Account'")}
          </Link>
          <img src={juantwo} alt="" className="check_in_modal_juan_two" />
        </div>
        <img src={juanone} alt="" className="check_in_modal_juan_one" />
      </Modal>
    </div>
  );
};

export default Navbar;
