import React from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

import "./Airdrop.less";
import useIsMobile from "../../hooks/useIsMobile";
import {getLang} from "../../utils/getLang";

const Home = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;

  const isMobile = useIsMobile();
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();



  return (
    <div className="home">

    </div>
  );
};

export default Home;
